import React, { useEffect, useState } from 'react';
import axios from "axios";
import Newsmodal from "../Component/NewsModal";
import styled from "styled-components";
import { Link } from "react-scroll";
import LinkENG from "../Component/LinkENG";
import LinkKOR from "../Component/LinkKOR";
import Logo from "../Component/Logo";
import "../css/Main.css";

function News(){
    const [newsData, setNewsData] = useState(null); //결과물, 요청의 결과
    const [error, setError] = useState(null);
    const [newsData1, setNewsData1] = useState(null);
    const [newsData2, setNewsData2] = useState(null);
    const [newsData3, setNewsData3] = useState(null);

    useEffect(()=>{
        const getNewsData = async ()=>{
          try{
            const response = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test1');
            const response1 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test2');
            const response2 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Qkd');
            const response3 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Network');
            console.log(response.data.data);
            setNewsData(response.data.data);
            setNewsData1(response1.data.data);
            setNewsData2(response2.data.data);
            setNewsData3(response3.data.data);  
          }
          catch (error) {
            setError(error);
          }
        };
        getNewsData();
      }, []);

      const Post = styled.div`
      border-radius: 20px;
      background: white;
      box-shadow: 10px 5px 5px #718fa6;
      border: 1px solid black;
      margin-top: 20px;
    `;

    const Title = styled.div`
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid black;
      font-weight: 600;
    `;

    const Body = styled.div`
      height: 80%;
      padding: 11px;
      border-radius: 20px;
    `;

    return(
        <div className='root'>
        <header>
        <div className="top">

           <Logo/> 
          {/* <div class="topwrap">
          
          </div> */}
          
          <div className="Menu">
					<div className="menu1"><Link to ="1" spy={true} smooth={true}>News1</Link></div>
					<div className="menu1"><Link to ="2" spy={true} smooth={true}>News2</Link></div>
					<div className="menu1"><Link to ="3" spy={true} smooth={true}>News3</Link></div>
				</div>

				<div className="language">
					<LinkKOR />
					<LinkENG />
				</div>
          </div>
          </header>

            <div className='wrap-newsbox'>
                <div className='firstbox'>
                    <ul>
                        {newsData && newsData.map((news, index) => {
                            <Post key={index}>
                                <a href={news.news_link}>
                                    <Title>{news.news_title}</Title>
                                    <Body>{news.news_link}</Body>
                                </a>
                            </Post>
                        })}
                    </ul>
                </div>
                <div className="Modal">
                    <Newsmodal />
                </div>
                <div className='secondbox'>

                </div>

            </div>
        </div>
    );
}

export default News;