import React from "react";
import Logo from "../Component/Logo";
import { Link } from "react-router-dom";
import LinkENG from "../Component/LinkENG";
import LinkKOR from "../Component/LinkKOR";

function Board(){

    return(
      <div className="root">
       <header>
        <div className="top">

           <Logo/> 
          {/* <div class="topwrap">
          
          </div> */}
          
          <div className="Menu">
					<div className="menu1"><Link to ="1" spy={true} smooth={true}>Simulator 특징</Link></div>
					<div className="menu1"><Link to ="2" spy={true} smooth={true}>Simulator 영상</Link></div>
					<div className="menu1"><Link to ="3" spy={true} smooth={true}>About us</Link></div>
				</div>
        <div>
          <linkNews />
        </div>

				<div className="language">
					<LinkKOR />
					<LinkENG />
				</div>
          </div>
          </header>

            <div class="Body">   {/*바디 구상 */}
               <div class="content">
                 <div class="boardname">자료실</div>

                  <div class="sub-content">게시판: 해야할건 게시판을 표로 만들기</div>
                  <div>
    
    </div>
               </div> 
            </div>

            <footer>
        <div class="footer">
          <p class="contact">Contact us<br />
          Address  서울특별시 성북구 동소문로 86-15<br />
            Email  info@qdotclab.com</p>
        </div>
      </footer>
        </div>
    );
};

export default Board;