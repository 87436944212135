import React, {useState} from "react";
import { Link } from "react-router-dom";

function handleClick() {
    if(window.location.pathname === "./Maine"){
        return true;
    };
      };

function LinkENG(){
    const [disable, setDisable] = useState(false);
    // disabled={disable} onClick={() => setDisable(true)}
    // location.pathname === "/Maine"
    // console.log(this.props.location);
    return(
        <Link to="./Maine"><button className="Ch" disabled={window.location.pathname === "/Maine"}> ENG</button></Link>
    );
};

export default LinkENG;