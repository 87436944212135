import React from "react";
import QdotC_logo from '../image/Logo.PNG';
import { Link } from "react-router-dom";
import "../css/Logo.css";

function Logo(){
    return(
            <img className="logo_image" src={QdotC_logo} alt="QdotC_logo" title="QdotC_logo"/>
    );
};

export default Logo;