import React,{useState} from "react";
import { Link } from "react-router-dom";
import '../css/Ch.css';

function LinkKOR(){
    
    return(
        <Link to="/"><button className="Ch">KOR</button></Link>
    );
};

export default LinkKOR;