import React from "react";
import "../css/Main.css";
import { Link } from "react-scroll";
import Logo from "../Component/Logo";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "../Component/ImageSlider";
import Whitebox from "../Component/Whitebox";
import ScrollButton from "../Component/ScrollButton";
// import footer_img from './image/footer.png';
import About from "../image/About.png";
import LinkENG from "../Component/LinkENG";
import LinkKOR from "../Component/LinkKOR";
import { BsFillXDiamondFill } from "react-icons/bs";
import linkNews from "../Component/linkNews";

function Main(){


    return(
      <div className="root">
        <header>
        <div className="top">

           <Logo/> 
          {/* <div class="topwrap">
          
          </div> */}
          
          <div className="Menu">
					<div className="menu1"><Link to ="1" spy={true} smooth={true}>Simulator 특징</Link></div>
					<div className="menu1"><Link to ="2" spy={true} smooth={true}>Simulator 영상</Link></div>
					<div className="menu1"><Link to ="3" spy={true} smooth={true}>About us</Link></div>
				</div>
        <div>
          <linkNews />
        </div>

				<div className="language">
					<LinkKOR />
					<LinkENG />
				</div>
          </div>
          </header>
      
      
    <div className="Body">
      <div className="ImageControl">
        <ImageSlider />
      </div>



        <div className="MAIN">
            {/* <ScrollButton /> */}
            <Whitebox />
        </div>

      <div className="about" id="3">
        <img className="About-us" src={About} />
        {/* <p>
          <h1 id="A">ABOUT US</h1>
          <h2><span className="Day">< BsFillXDiamondFill/>2021.04</span> <span className="Content">(주)큐닷씨연구소 설립</span> </h2>
          <h2><span className="Day">< BsFillXDiamondFill/>2021.08</span> <span className="Content">벤처기업인증</span> </h2>
          <h2><span className="Day">< BsFillXDiamondFill/>2022.02</span> <span className="Content">한국통신학회 동계종합학술발표회 시연</span> </h2>

        </p> */}
      </div>
      </div>
      


      <footer>
        <div className="footer">
          {/* <img className="Contact" src={footer_img} /> */}
          <p>
            <h1 id="Cid">Contact</h1>
            <h2 className="Fcontent">Address  서울특별시 성북구 동소문로 86-15</h2>
            <h2 className="Fcontent">Email  info@qdotclab.com</h2>
          </p>
        </div>
      </footer>

    </div>
    );

};

export default Main;