import React,{ useState } from "react";
import Modal from 'react-modal';
import DataTable1 from "./DataTable1";
import '../css/NewsModal.css';

Modal.setAppElement('#root');
function Newsmodal(){
    const [modalIsOpen, setModalIsOpen] = useState(false);


    return (
        <div>
            <button onClick={() => setModalIsOpen(true)}>수정</button>
            <Modal 
                className="ModalOpenBtn"
                isOpen={modalIsOpen} 
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={() => setModalIsOpen(false)}
                    style={
                        {
                            overlay: {
                                backgroundColor: 'grey'
                            },
                            content: {
                                color: 'orange'
                            }
                        }
                    }
                    >
                
                <DataTable1 />
                
                <div>
                    <button 
                    className="ModalCloseBtn"
                    onClick={()=>setModalIsOpen(false)}>수정 완료</button>
                </div>
            </Modal>
        </div>
    );
}

//whitebox id를 받아와서 Modal에서 if문으로 돌려줘야함
//if(id===1)이런식으로 해서 해당 숫자에 해당하는 DataTableComponenet를 실행시켜줘야함
//그렇다고 각 Table의 Componenet를 만드는건 문제가 있을 것 같음

export default Newsmodal;
