import React,{useState, useEffect} from "react";
// import axios from "axios";


function DataTable1(){
    const [newsData, setNewsData] = useState(null);
    const [error, setError] = useState(null);
    // useEffect(()=>{
    //     const getNewsData = async ()=>{
    //       try{
    //         const response = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test1');
    //         console.log(response.data.data);
    //         setNewsData(response.data.data);
    //       }
    //       catch (error) {
    //         setError(error);
    //       }
    //     };
    //     setNewsData(newsData && newsData.map((d, index) => {
    //       return{
    //         "select":false,
    //         id: index,  
    //         news_title:d.news_title, 
    //         news_link:d.news_link
    //       }; 
    //     })
    //     );
    //     getNewsData();
        
    //   }, []);
      
    // useEffect(() => {
    //   let newsData = [
    //     {id: 1, news_title:"qkd", news_link: "www.naver.com"},
    //     {id: 2, news_title:"qkd1", news_link: "www.google.com"},
    //     {id: 3, news_title:"qkd2", news_link: "www.youtube.com"},
    //   ];

      // setNewsData(newsData.map(d => {
      //   return{
      //     "select":false,
      //     id: d.id,  
      //     news_title:d.news_title, 
      //     news_link:d.news_link
      //   }; 
      // })
      // );
    // }, []);

      return(
        <table className="News-Table">
            <thead>
                <tr>
                  <th scope="col">
                    <input type="checkbox" onChange={(e)=>{
                      let checked=e.target.checked;
                      setNewsData(newsData.map((d)=>{
                        d.select=checked;
                        return d;
                      }))
                    }}></input>
                  </th>
                  <th scope="col">Title</th>
                  <th scope="col">Link</th>
                </tr>
            </thead>
            <tbody>
              {newsData && newsData.map((d,index) => (
                <tr key={index}>

                <th scope="row">
                  <input onChange={(event)=>{
                    let checked=event.target.checked;
                    setNewsData(newsData.map(data=>{
                      if(index === data.id){
                        data.select=checked;
                      }
                      console.log(index);
                      console.log(data.id);
                      return data; 
                    }));
                  }} type="checkbox" checked={d.select} ></input>
                  </th>
                <td>{d.news_title}</td>
                <td>{d.news_link}</td>
                </tr>
              ))}
            </tbody>
        </table>
      );
}

//Data에 id가 없기 때문에 id를 지정해줘야하나 싶음
//id를 지정해주면 그 아이디에 맞게 돌아갈거임


export default DataTable1;
