import React, { useEffect, useState } from "react";
import '../css/Whitebox.css';
// import axios from "axios";
import styled from "styled-components";
// import Newsmodal from "./NewsModal";
import Image1 from '../image/simulator.png';
import Image2 from '../image/simulator_video.png';
import ReactPlayer from "react-player";


function Whitebox(){

    const [newsData, setNewsData] = useState(null); //결과물, 요청의 결과
    const [error, setError] = useState(null);
    const [newsData1, setNewsData1] = useState(null);
    const [newsData2, setNewsData2] = useState(null);
    const [newsData3, setNewsData3] = useState(null);
    const [videoFilePath, setVideoFilePath] = useState(null);

    const handleVideoUpload = (event) => {
      const [file] = event.target.files;
      setVideoFilePath(URL.createObjectURL(file));
    };

    // useEffect(()=>{
    //   const getNewsData = async ()=>{
    //     try{
    //       const response = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test1');
    //       const response1 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test2');
    //       const response2 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Qkd');
    //       const response3 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Network');
    //       console.log(response.data.data);
    //       setNewsData(response.data.data);
    //       setNewsData1(response1.data.data);
    //       setNewsData2(response2.data.data);
    //       setNewsData3(response3.data.data);  
    //     }
    //     catch (error) {
    //       setError(error);
    //     }
    //   };
    //   getNewsData();
    // }, []);      
    //useEffect를 사용하여 로드시에 자동으로 get하도록 설정함

    // const Post = styled.div`
    //   border-radius: 20px;
    //   background: white;
    //   box-shadow: 10px 5px 5px #718fa6;
    //   border: 1px solid black;
    //   margin-top: 20px;
    // `;

    // const Title = styled.div`
    //   height: 20%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-bottom: 1px solid black;
    //   font-weight: 600;
    // `;

    // const Body = styled.div`
    //   height: 80%;
    //   padding: 11px;
    //   border-radius: 20px;
    // `;

    return(
        <div>
        <div className="Wrap-whitebox">
        <h1 className="Sim">양자암호통신 S/W 시뮬레이터 특징</h1>
        <div className="whitebox1" id="1">
              <img src={Image1} id="Simulator-distinction" />
        </div>
        <h1 className="Sim1">시뮬레이터</h1>
        <div className="whitebox" id="2">
          {/* <h1 className="Sim1">시뮬레이터</h1> */}
          <ReactPlayer 
          className='react-player'
          url={'https://youtu.be/JHWgzarrykQ'}
          height='100vh'
          width='100%'  
          controls 
          muted
          playing
          />
        </div>
        </div>
        </div>
    );
}


export default Whitebox;