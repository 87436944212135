import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../image/wordcloud1.png';
import Image2 from '../image/wordcloud2.png';
import "../css/Imageslider.css";

function ImageSlider(){
    const settings = {
        dots: true,
        fade: true,
        autoplay:true,
        cssEase: "linear",
        autoplaySpeed: 2000,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,  //한 화면에 보이는 콘텐츠 수
        slidesToScroll: 1, //한번에 넘어가는 콘텐츠 수
        // adaptiveHeight: true
      };


      return(
        <div className="ImgSlider">
            <Slider {...settings}>
          <div className='class-image'>
            <img id="first-img" src={Image2} />
          </div> 
            <div className='class-image'>
            <img id="second-img" src={Image1} />
          </div>
         </Slider>
        </div>
      );
};

export default ImageSlider;