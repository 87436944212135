import React, { useEffect, useState } from "react";
import '../css/Whitebox.css';
// import axios from "axios";
import styled from "styled-components";
// import Newsmodal from "./NewsModal";
import Image1 from '../image/simulatore.png';
import Image2 from '../image/simulator_video.png';
import ReactPlayer from "react-player";


function Whitebox(){

    const [newsData, setNewsData] = useState(null); //결과물, 요청의 결과
    const [error, setError] = useState(null);
    const [newsData1, setNewsData1] = useState(null);
    const [newsData2, setNewsData2] = useState(null);
    const [newsData3, setNewsData3] = useState(null);
    const [videoFilePath, setVideoFilePath] = useState(null);

    const handleVideoUpload = (event) => {
      const [file] = event.target.files;
      setVideoFilePath(URL.createObjectURL(file));
    };

    // useEffect(()=>{
    //   const getNewsData = async ()=>{
    //     try{
    //       const response = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test1');
    //       const response1 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=test2');
    //       const response2 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Qkd');
    //       const response3 = await axios.get('https://qdotclab-api.cf/news/exist/?keyword=Quantum%20and%20Network');
    //       console.log(response.data.data);
    //       setNewsData(response.data.data);
    //       setNewsData1(response1.data.data);
    //       setNewsData2(response2.data.data);
    //       setNewsData3(response3.data.data);  
    //     }
    //     catch (error) {
    //       setError(error);
    //     }
    //   };
    //   getNewsData();
    // }, []);      
    //useEffect를 사용하여 로드시에 자동으로 get하도록 설정함

    // const Post = styled.div`
    //   border-radius: 20px;
    //   background: white;
    //   box-shadow: 10px 5px 5px #718fa6;
    //   border: 1px solid black;
    //   margin-top: 20px;
    // `;

    // const Title = styled.div`
    //   height: 20%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-bottom: 1px solid black;
    //   font-weight: 600;
    // `;

    // const Body = styled.div`
    //   height: 80%;
    //   padding: 11px;
    //   border-radius: 20px;
    // `;

    return(
        <div>
        <div className="Wrap-whitebox">
        <h1 className="Sim">Quantumn Cryptography S/W Simulator Distinction</h1>
        <div className="whitebox1" id="1">
              <img src={Image1} id="Simulator-distinction"/>
        </div>
        <h1 className="Sim1">Simulator</h1>
        <div className="whitebox" id="2">
          {/* <h1 className="Sim1">시뮬레이터</h1> */}
          <ReactPlayer 
          className='react-player'
          url={'https://youtu.be/JHWgzarrykQ'}
          height='100vh'
          width='100%' 
          controls 
          muted
          playing
          />
        </div>
        </div>
        </div>
    );
}


//default는 axios를 통해 get한 뉴스들을 전부 가져와서 보여주기
//수정 버튼을 누르면 해당 부분의 뉴스들을 axios를 통해 get하고 get한 목록들을 checkbox를 앞에 붙여서 띄워줌
//checkbox에서 원하는 것들을 체크하고 수정완료 버튼을 누름
//수정 완료 버튼을 누르면 checkbox에서 체크되어 있던 뉴스들만 whitebox에 표기되어야함

//현재 default로 axios를 통해 뉴스들을 get해서 보여주는거 완료
//수정 버튼 생성 완료, 수정버튼을 누르면 Modal을 통해서 Modal이 나오도록함
//Table에서 axios를 통해 뉴스들을 get하고 이를 checkbox를 앞에 둔 채로 table을 생성
//checkbox에서 원하는 것들을 체크할 수 있도록 설정
//수정완료 누르면 Modal이 꺼짐

//해야하는 것: 수정 완료를 눌렀을 때 Checkbox에서 Checked상태인것만 Whitebox에 표시해야함


export default Whitebox;