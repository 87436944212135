import React from 'react';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import Main from './pages/Main';
import Maine from './pages/Maine';
import News from './pages/news';
import Board from './pages/Board';

function App() {

  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/news' element={<News />} />
        <Route path='/' element={<Main />}/>
        <Route path='/Maine' element={<Maine />} />
        <Route path='/Board' element={<Board />} />      
        </Routes>
      </BrowserRouter> 


    </div>
  );
};

export default App;

