import React from "react";
import '../css/ScrollButton.css';
import {Link} from "react-scroll";

function ScrollButton(){
    return(
        <div className="buttonsort">
        <div className="chapter">
            <Link to ="1" spy={true} smooth={true}>
             <button className="Btn1">
            test1
            </button>
            </Link>
            </div>
            
            <div className="chapter">
            <Link to ="2" spy={true} smooth={true}>
            <button className="Btn1">
            test2
              </button>
            </Link>
            </div>
            <div className="chapter">
            <Link to ="3" spy={true} smooth={true}>
            <button className="Btn1">
            Quantum%20and$20Qkd
            </button>
            </Link>
            </div>
            {/* <div className="chapter">
            <Link to ="4" spy={true} smooth={true}>
            <button key="3" className="Btn1">
            Quantum%20and%20Network
            </button>
            </Link>
            </div> */}
            </div>
    );
}

export default ScrollButton;